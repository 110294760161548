import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: calc(100vh - 40px);
  font-family: "Cormorant Unicase", serif;
`;

export const Profile = styled.div`
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box; 
  
  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 80%;
  z-index: 1;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const LinkBlock = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dotted white;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  width: 100%;
  margin: 10px;
  cursor: pointer;
  background-color: black;

  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #8d8a61;
    border-color: white;
    transform: translateY(-2px) scale(1.02) perspective(1000px) rotateX(5deg);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  p {
    color: #ffff;
    text-decoration: none;
    font-weight: 700;
    width: 100%;
  }

  ion-icon {
    font-size: 25px;
    color: white;

    &:hover {
      color: black;
      transform: scale(1.1);
      z-index: 1;
    }
  }
`;
