import React from "react";
import ParticlesComponent from "../components/Particles/Particles";
import { Container, Box, LinkBlock, Profile } from "./styles";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import socialLinks from "../constants/socialLinks";

const Icon = ({ iconName }) => <ion-icon name={iconName}></ion-icon>;

export default function HomePage() {
  return (
    <Container>
      <ParticlesComponent />
      <Profile>
        <Header />
        <Box>
          {socialLinks.map((link, index) => (
            <LinkBlock key={index} href={link.url} target="_blank" rel="noreferrer">
              <Icon iconName={link.icon} />
              <p> {link.name} </p>
            </LinkBlock>
          ))}
        </Box>
        <Footer />
      </Profile>
    </Container>
  );
}
