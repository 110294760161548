import { Container, ProfileImage, Name, Title } from "./styles";
import me from "../../assets/images/me.webp";

export default function Header() {
  return (
    <Container>
      <ProfileImage src={me} alt="Susana" />
      <Name>Susana Natividade</Name>
      <Title>Software Engineer</Title>
    </Container>
  );
}
