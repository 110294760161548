import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  width: 100%;
  z-index: 1;
  font-family: "Cormorant Unicase", serif;
 
  @media (max-width: 768px) {
    width: 340px;
  }
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  height: 14vh;
  margin-bottom: 10px;
`;

export const Name = styled.div`
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 1em;
  color: white;
`;
