const socialLinks = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/natividadesusana/",
    icon: "logo-linkedin",
  },
  {
    name: "GitHub",
    url: "https://github.com/natividadesusana",
    icon: "logo-github",
  },
  {
    name: "E-mail",
    url: "mailto:susanajdsn@gmail.com",
    icon: "mail-outline",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/natividadesusana/",
    icon: "logo-instagram",
  },
  {
    name: "Django Girls",
    url: "https://djangogirls.org/pt-br/",
    icon: "earth-outline",
  },
  {
    name: "PyLadies",
    url: "https://pyladies.com/",
    icon: "globe",
  },
  {
    name: "PyLadies Brazil",
    url: "https://brasil.pyladies.com/about/",
    icon: "sparkles-outline",
  },
];

export default socialLinks;
