import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Cormorant Upright", serif;

  @media (max-width: 768px) {
    width: 340px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  h1 {
    font-size: 18px;
    color: white;
    text-align: center;
    line-height: 25px;
  }

  .separator {
    border: 0.1px solid gray;
    width: 15%;
    margin-top: 5px;
    margin-bottom: 8px;
  }
`;

export const IconsContainer = styled.div`
  ion-icon {
    font-size: 25px;
    margin: 8px;
    color: white;
    cursor: pointer;

    &:hover {
      color: gray;
      transform: scale(1.1);
      z-index: 1;
    }
  }
`;
